import { useEffect } from "react";
import { Spacer } from "../../component/Spacer";
import { FilterOptionButton } from "../thumbnail_list/component/FliterOptionButton";
import { CategoryButton } from "../thumbnail_list/component/CategoryButton";
import { OriginalSearchButton } from "../thumbnail_list/component/OriginalSearchButton";
import { StyleButton } from "../thumbnail_list/component/StyleButton";
import { AgeSelectButtonWrapper } from "../thumbnail_list/component/AgeSelectButton";
import { ThumbnailVideoPlayer } from "../thumbnail_list/ThumbnailVideoPlayer";
import { ThumbnailContainer } from "../../component/ThumbnailContainer";
import { IoIosSearch } from "react-icons/io";
import { useThumbnailListStore } from "./SearchStore";
import { useHeaderStore } from "../global/header/HeaderStore";

const SearchPage = () => {

  const store = useThumbnailListStore()
  const headerStore = useHeaderStore()

  useEffect(() => {

    if (headerStore.loginState == 'unload') {
      return
    }

    const params = new URLSearchParams(window.location.search);
    const searchValue = params.get('q');

    store.init(searchValue ?? '', headerStore.loginState == 'login')

    return (
      store.leave()
    )
  }, [headerStore.loginState])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // IMEなし Enterキーが押されたとき
      if (event.key === 'Enter') {
        store.searchTapped()
      }

      // IMEあり Enterキーが押されたとき
      if (event.code === 'Enter') {
        if (event.isComposing) {
          store.searchTapped()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);


  return (
    <div className="sm:px-4">
      <div
        className={"container mx-auto"}
      >

        <Spacer size={10} axis={'vertical'} />

        <div className={'sm:w-[600px]  mx-auto flex items-center justify-center'}>

          <div
            className={'relative w-full flex items-center justify-center'}
          >
            <div className="absolute left-6">
              <IoIosSearch className="text-neutral-500" size={24} />
            </div>

            <input
              value={store.searchTagValue}
              placeholder={'タグを検索'}
              onChange={(e) => store.searchTagValueChange(e.target.value)}
              className={' caret-neutral-500 bg-neutral-100 outline-8 outline-blue-500 border-2 border-neutral-200 pl-14 pr-4 w-full mx-auto rounded-full p-3'}
            />
          </div>

        </div>

        <Spacer size={10} axis={'vertical'} />

        <StyleButton />
        <Spacer size={10} axis={'vertical'} />
        <CategoryButton />

        <div
          className={'sm:flex justify-between items-center'}
        >
          <OriginalSearchButton />
          <div
            className="flex sm:block justify-end mb-3 sm:mb-0"
          >
            <AgeSelectButtonWrapper
              onClick={store.setAgeLimitButton}
              is_adult={store.isAdult}
              age_limit_button={store.ageLimitButton}
            />
          </div>
        </div>

        <FilterOptionButton />

        <Spacer size={10} axis={'vertical'} />

        <ThumbnailContainer
          state={store.state}
          totalSize={store.totalSize}
          currentPage={store.currentPage}
          onClick={store.pageNationButtonTapped}
        >
          {
            store.searchPostVideoDataList.map((value) => {
              return (
                <ThumbnailVideoPlayer
                  showProfile={true}
                  key={value.post_id}
                  postVideoData={value}
                />
              )
            })
          }
        </ThumbnailContainer>


        {
          store.searchPostVideoDataList.length === 0 && store.state === 'loaded' &&
          <div className="h-64">
            <p className="p-2">投稿が見つかりませんでした</p>
            <Spacer size={30} axis={'vertical'} />
          </div>
        }

      </div>
    </div>
  )
}

export default SearchPage