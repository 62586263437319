import { Spacer } from "../../component/Spacer";
import { AsyncLoadingAnimationButton } from "../../component/AsyncLoadingAnimationButton";
import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { PasswordInput } from "./PasswordInput";

export const EmailAndPasswordSubmitForm = (
  {
    onEmailChange,
    onPasswordChange,
    submitButtonPressed,
    errorMessage,
    placeholdar,
    isSubmitting,
    isLogin,
    centerChildren
  }: {
    isShowingPassword: boolean,
    onEmailChange: (email: string) => void,
    onPasswordChange: (password: string) => void,
    changeShowPassword: () => void,
    submitButtonPressed: () => void,
    errorMessage: string,
    placeholdar: string,
    isSubmitting: boolean,
    isLogin: boolean,
    centerChildren?: ReactNode
  }
) => {

  const navigate = useNavigate()

  return (
    <div>
      <div className={'mx-auto w-96 h-[500px]  rounded-3xl border-2 border-neutral-200 bg-white'}>

        <div className={'flex flex-col items-center justify-center'}>

          <Spacer size={50} axis={"vertical"} />

          <p className={'text-2xl font-bold text-neutral-800'}>anishare</p>

          <p className="text-neutral-500">{placeholdar}</p>

          <Spacer size={30} axis={"vertical"} />

          <div className={'w-80 h-80'}>
            <div className={'h-4 w-full'}>
              <p className={'text-red-600 text-xs text-center'}> {errorMessage} </p>
            </div>

            <Spacer size={5} axis={'vertical'} />

            <input
              tabIndex={0}
              type={'email'}
              placeholder={'メールアドレス'}
              onChange={(e) => {
                onEmailChange(e.currentTarget.value)
              }}
              className={' text-neutral-700 placeholder:text-neutral-500  bg-gray-100 border-neutral-200 w-full rounded-md p-3 outline-blue-500 '}
            />

            <Spacer size={10} axis={'vertical'} />

            <PasswordInput
              onPasswordChange={onPasswordChange}
            />

            <Spacer size={30} axis={"vertical"} />

            {
              centerChildren
            }

            <div className={'flex justify-center'}>
              <AsyncLoadingAnimationButton
                isSubmitOk={true}
                isSubmitting={isSubmitting}
                onSubmitButtonTapped={submitButtonPressed}
                placeholder={placeholdar}
                fullWidth={true}
              />
            </div>

            {
              isLogin &&
              <div>
                <Spacer size={30} axis={'vertical'} />

                <div className={'flex justify-center'}>
                  <Spacer size={10} axis={'horizontal'} />
                  <button
                    onClick={() => {
                      navigate("/change_password")
                    }}
                    className={'text-blue-500 text-base'}>
                    パスワードを忘れた場合
                  </button>
                </div>

                <Spacer size={10} axis={'vertical'} />

                <div className={'flex justify-center'}>
                  <Spacer size={10} axis={'horizontal'} />
                  <button onClick={() => {
                    navigate("/register")
                  }} className={'text-blue-500 text-base'}>アカウントを作成する
                  </button>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}