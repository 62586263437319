import { FetchClient } from "../../../network/AnishareFetch";

export interface UserNotifyData {
  notifyCount: number
  authId: string
}

export interface NotifyResponse {
  notifies: NotifyData[]
}

type NotifyPaylaod = {
  commentData: CommentData | null
  profileData: ProfileData | null
  postData: PostData | null
}

type CommentData = {
  commentId: string
  text: string
}

type ProfileData = {
  authId: string
  name: string
  profileId: string,
  iconId: string
}

type PostData = {
  postId: string
  title: string
  thumbnailId: string
}

export interface NotifyData {
  notifyId: string
  authId: string
  type: NotifyType
  payload: NotifyPaylaod
  createdAt: number
}

export type NotifyType = 'FOLLOW' | 'LIKE' | 'COMMENT'

export class NotifyClient {

  static async getUserNotify() {
    const result = await FetchClient.get(`/user_notify`, true);
    return await result.json() as UserNotifyData
  }

  static async getNotifies() {
    const result = await FetchClient.get(`/notifies`, true);
    return await result.json() as NotifyResponse
  }
}