import classNames from "classnames";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const PageNation = (
  {
    currentPage,
    onClick,
    totalSize,
    onePageDataSize
  }
    :
    {
      currentPage: number,
      onClick: (value: number) => void,
      totalSize: number,
      onePageDataSize: number
    }
) => {
  const buttonColorClazz = classNames('hover:bg-neutral-100 cursor-pointer h-7 w-7 rounded-md flex items-center justify-center')

  const textColor = 'text-neutral-500'

  const maxPage = Math.ceil(totalSize / onePageDataSize)

  const pageList = getPagination(maxPage, currentPage)

  return (
    <div>
      <div
        className={
          'flex justify-center'
        }
      >
        <div
          className={
            classNames(
              buttonColorClazz,
              {
                'invisible': currentPage === 1
              }
            )
          }
          onClick={() => {
            window.scrollTo(0, 0)
            onClick(currentPage - 1)
          }}
        >
          <FaChevronLeft className={textColor} />
        </div>

        <div
          className={'mx-10 flex justify-center gap-3'}
        >
          {pageList.map((value) => {
            const clazz = classNames(
              buttonColorClazz,
              {
                'bg-neutral-200': currentPage === value
              }
            )

            return (
              <div
                key={value}
                className={clazz}
                onClick={() => {
                  window.scrollTo(0, 0)
                  onClick(value)
                }}
              >
                <p className={'text-neutral-500'}>
                  {value}
                </p>
              </div>
            )
          })}
        </div>

        <div
          className={
            classNames(
              buttonColorClazz,
              {
                'invisible': currentPage === maxPage
              }
            )
          }
          onClick={() => {
            window.scrollTo(0, 0)
            onClick(currentPage + 1)
          }}
        >
          <FaChevronRight className={textColor} />
        </div>
      </div>
    </div>
  )
}

function getPagination(maxPage: number, currentPage: number): number[] {
  const visiblePages = 5; // 表示するページ数
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2)); // 開始ページ
  let endPage = startPage + visiblePages - 1; // 終了ページ

  // 終了ページが最大ページを超える場合の調整
  if (endPage > maxPage) {
    endPage = maxPage;
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  // ページ番号のリストを作成
  const pages: number[] = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
}