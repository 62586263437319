import {Spacer} from "../../component/Spacer";

export const CheckUserIsAdultModal = (
  {
    onNoButtonTapped,
    onYesButtonTapped
  }: {
    onNoButtonTapped: () => void,
    onYesButtonTapped: () => void
  }
) => {

  return (
    <div
      className={'w-[500px] h-[350px] bg-white rounded-3xl'}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div
        className={'p-2'}
      >
        <Spacer size={30} axis={'vertical'}/>
        <p className={'flex items-center justify-center w-full text-3xl'}>年齢確認画面</p>

        <Spacer size={30} axis={'vertical'}/>
        <p>このページには成人向けコンテンツを扱う部分があります。</p>

        <Spacer size={10} axis={'vertical'}/>
        <p>18歳以下のアクセスを禁じています。あなたは18歳以上ですか?</p>
        <Spacer size={10} axis={'vertical'}/>

        <Spacer size={50} axis={'vertical'}/>
        <div className={'flex w-full justify-center items-center '}>
          <div>
            <button
              onClick={onYesButtonTapped}
              className={'bg-green-700 text-white rounded-md p-4'}
            >
              はい 18歳以上です
            </button>
          </div>
          <Spacer size={30} axis={'horizontal'}/>
          <div>
            <button
              onClick={onNoButtonTapped}
              className={'bg-red-700 text-white rounded-md p-4'}
            >
              いいえ 18歳未満です
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}