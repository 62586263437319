import { TbReload } from "react-icons/tb";
import classNames from "classnames";
import { Spacer } from "./Spacer";

export const AsyncLoadingAnimationButton = (
  {
    isSubmitOk,
    isSubmitting,
    onSubmitButtonTapped,
    placeholder,
    fullWidth,
    textsmall,
    noPx,
  }
    :
    {
      isSubmitOk: boolean,
      isSubmitting: boolean,
      onSubmitButtonTapped: () => void,
      placeholder: string,
      fullWidth?: boolean,
      textsmall?: boolean,
      noPx?: boolean,
    }
) => {

  const submitButtonClazz = classNames(
    "py-2 bg-blue-500 hover:bg-blue-400 rounded-full text-white flex items-center justify-center",
    {
      "opacity-30": !isSubmitOk,
    },
    {
      "w-full": fullWidth
    },
    {
      "text-sm": textsmall
    },
    {
      "px-10": !noPx
    },
    {
      "px-4": noPx
    }
  )

  const buttonClazz = classNames(
    {
      "w-full": fullWidth
    }
  )

  return (
    <button tabIndex={0} className={buttonClazz} disabled={!isSubmitOk} onClick={onSubmitButtonTapped}>
      <div className={'relative w-full'}>
        <div className={submitButtonClazz}>
          <p>{placeholder}</p>
          {
            isSubmitting && <div>
              <Spacer axis={'horizontal'} size={5} />
              <TbReload size={20} className={'animate-spin text-white'} />
            </div>
          }
        </div>
      </div>
    </button>
  )
}