import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation(); // 現在のパスを取得

  useEffect(() => {
    window.scrollTo(0, 0); // ページの一番上へスクロール
  }, [pathname]); // パスが変わったら実行

  return null;
};