import classNames from "classnames";
import { Spacer } from "../../../component/Spacer";
import { useThumbnailListStore } from "../../search/SearchStore";

export const StyleButton = () => {
  const store = useThumbnailListStore()

  const button2DClazz = classNames(
    "p-1",
    "text-neutral-500",
    "border-b-4",
    "font-medium",
    "hover:text-neutral-700",
    "border-neutral-200",
    {
      "border-neutral-500": store.style === '2D',
      "text-neutral-700": store.style === '2D',
    }
  )

  const button3DClazz = classNames(
    "p-1",
    "text-neutral-500",
    "border-b-4",
    "font-medium",
    "hover:text-neutral-700",
    "border-neutral-200",
    {
      "border-neutral-500": store.style === '3D',
      "text-neutral-700": store.style === '3D',
    }
  )


  const buttonRealClazz = classNames(
    "p-1",
    "text-neutral-500",
    "border-b-4",
    "font-medium",
    "hover:text-neutral-700",
    "border-neutral-200",
    {
      "border-neutral-500": store.style === 'REAL',
      "text-neutral-700": store.style === 'REAL',
    }
  )

  return (
    <div>
      <div className={'grid h-8 items-center justify-center grid-cols-3 text-center '}>

        <button onClick={() => store.styleButtonTapped('2D')}>
          <div className={button2DClazz}>2D</div>
        </button>

        <button onClick={() => store.styleButtonTapped('3D')}>
          <div className={button3DClazz}>3D</div>
        </button>

        <button onClick={() => store.styleButtonTapped('REAL')}>
          <div className={buttonRealClazz}>実写(コマ撮り)</div>
        </button>

        <Spacer size={1} axis={'vertical'} />

      </div>
      <div className={'border-t  translate-y-1'} />
    </div>
  )
}