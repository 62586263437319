
export class JwtHelper {

  static isTokenExpiredAfter3Minutes(token: string) {
    const expiry = JwtHelper.getTokenExpireDate(token);

    const threeMinutes = 3 * 60 * 1000
    return Date.now() + threeMinutes > expiry;
  }

  static isTokenExpiredAfter1Week(token: string) {
    const expiry = JwtHelper.getTokenExpireDate(token);

    const oneWeek = 7 * 24 * 60 * 60 * 1000
    return Date.now() + oneWeek > expiry;
  }

  static isTokenExpiredAfter1Month(token: string) {
    const expiry = JwtHelper.getTokenExpireDate(token);

    const oneMonth = 30 * 24 * 60 * 60 * 1000
    return Date.now() + oneMonth > expiry;
  }

  static isTokenExpired(token: string) {
    const expiry = JwtHelper.getTokenExpireDate(token);
    return Date.now() > expiry;
  }

  private static getTokenExpireDate(token: string) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000; // expは秒単位であるためミリ秒に変換
  }
}