import {AuthClient} from "../../test/network/auth/AuthClient";
import {JwtHelper} from "../../helper/JwtHelper";

class AccessTokenRefresher {
  static ACCESS_TOKEN = "accessToken"

  setAccessToken(token: string) {
    localStorage.setItem(AccessTokenRefresher.ACCESS_TOKEN, token)
  }

  async getAccessToken(refreshToken: string) {
    const accessToken = localStorage.getItem(AccessTokenRefresher.ACCESS_TOKEN)

    if (accessToken !== null && !JwtHelper.isTokenExpiredAfter3Minutes(accessToken)) {
      return accessToken
    }

    const response = await AuthClient.accessToken(refreshToken)
    if (response.isError || response.accessToken === undefined) {
      return null
    }

    const updateAccessToken = response.accessToken
    this.setAccessToken(updateAccessToken)
    return updateAccessToken
  }
}

class RefreshTokenRefresher {
  static REFRESH_TOKEN_KEY = "refreshToken"

  setRefreshToken(token: string) {
    if (token === undefined) {
      return
    }
    localStorage.setItem(RefreshTokenRefresher.REFRESH_TOKEN_KEY, token)
  }

  async getRefreshToken() {
    const refreshToken = localStorage.getItem(RefreshTokenRefresher.REFRESH_TOKEN_KEY)

    if (refreshToken === null) {
      return null
    }

    if (JwtHelper.isTokenExpired(refreshToken)) {
      return null
    }

    if (!JwtHelper.isTokenExpiredAfter1Week(refreshToken)) {
      return refreshToken
    }

    const response = await AuthClient.refreshTokenUpdate(refreshToken)
    if (response.isError || response.refreshToken === undefined) {
      return null
    }

    const updatedRefreshToken = response.refreshToken
    this.setRefreshToken(updatedRefreshToken)
    return updatedRefreshToken
  }
}


class AuthService {
  private refreshTokenRefresher = new RefreshTokenRefresher()
  private accessTokenRefresher = new AccessTokenRefresher()

  logout() {
    localStorage.removeItem(RefreshTokenRefresher.REFRESH_TOKEN_KEY)
    localStorage.removeItem(AccessTokenRefresher.ACCESS_TOKEN)
  }

  async getRefreshToken() {
    return await this.refreshTokenRefresher.getRefreshToken()
  }

  login(token: string) {
    localStorage.removeItem(AccessTokenRefresher.ACCESS_TOKEN)
    this.refreshTokenRefresher.setRefreshToken(token)
  }

  async getAccessToken() {
    const refreshToken = await this.getRefreshToken()

    if (refreshToken === null) {
      return null
    }

    return await this.accessTokenRefresher.getAccessToken(refreshToken)
  }
}

export const authService = new AuthService()
