import { Link, Outlet, useNavigate } from "react-router-dom";
import { FiPlus, FiUsers } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import { AuthClient } from "../../../test/network/auth/AuthClient";
import { Spacer } from "../../../component/Spacer";
import { authService } from "../../auth/AuthService";
import { ScrollToTop } from "../../../helper/ScrollToTop ";
import { useEffect, useRef } from "react";
import { useHeaderStore } from "./HeaderStore";
import { ReactComponent as Anishare } from "./anishare.svg"
import { Notifiycation } from "./Notification";
import { PullDownMenuButton } from "./PullDownMenuButton";
import { useLoginStore } from "../../auth/LoginPage";

export const Header = () => {
  const store = useHeaderStore()
  const loginState = useLoginStore()
  const dropDownMenuRef = useRef<HTMLDivElement>(null)

  const onClick = (e: MouseEvent) => {
    const isShowingDropDownMenu = store.isShowingDropDownMenu

    if (dropDownMenuRef.current == null) {
      return
    }

    if (!isShowingDropDownMenu) {
      return
    }

    if (dropDownMenuRef.current.contains(e.target as Node)) {
      return
    }
  }

  useEffect(() => {
    store.init()
    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }
  }, []);

  const navigate = useNavigate()
  const clazz = 'px-1 py-2 flex items-center hover:bg-neutral-100 rounded-md'

  return (
    <div>
      <ScrollToTop />
      {
        <>
          <div className={'bg-white'}>
            <div className={'mx-auto w-full 2xl:container relative'}>
              <div className={'flex gap-1 justify-end items-center pr-2 py-2 text-sm sm:text-base'}>

                <div className="absolute left-0 pl-3">
                  <div
                    className="flex items-center gap-2"
                  >
                    <Link to={'/'}>
                      <Anishare className="w-32" />
                    </Link>

                  </div>
                </div>

                {
                  store.loginState === 'not_login' && <>
                    <Link to={'/login'}>
                      <div className={'flex items-center'}>
                        <div className={'px-4 bg-rose-500 rounded-3xl '}>
                          <p className={'p-1 text-white font-bold'}>ログイン</p>
                        </div>
                      </div>
                    </Link>
                  </>
                }
                {
                  store.loginState === 'login' && <>
                    <Link to={"/post"}>
                      <div
                        className="hidden sm:block"
                      >
                        <div className={clazz}>
                          <FiPlus />
                          <p>投稿する</p>
                        </div>
                      </div>
                    </Link>
                    <Link to={"/following-new"}>
                      <div
                        className="hidden sm:block"
                      >
                        <div className={clazz}>
                          <FiUsers size={20} />
                          <p className="hidden sm:block" >フォロー新着</p>
                        </div>
                      </div>
                    </Link>
                  </>
                }
                <Link to={"/search"}>
                  <div className={clazz}>
                    <IoIosSearch size={20} />
                    <p className="hidden sm:block">検索</p>
                  </div>
                </Link>

                {
                  store.loginState === 'login' && <Notifiycation />
                }

                {
                  store.loginState === 'login' && store.iconSrc != undefined && <div className="flex items-center">
                    <Spacer axis="horizontal" size={5} />
                    <button onClick={store.showDropDownMenu}>
                      <div className={'flex items-center rounded-full border border-neutral-200'}>
                        <img className={'rounded-full h-9 w-9 shrink-0 '} src={store.iconSrc} alt={'profile'}></img>
                      </div>
                    </button>
                  </div>
                }
              </div>
              {
                store.isShowingDropDownMenu &&
                <div
                  className={'absolute right-5 top-10 z-50'}
                  ref={dropDownMenuRef}
                >
                  <div
                    className={'bg-white rounded-md shadow-lg border-2 border-neutral-200'}
                  >
                    <PullDownMenuButton
                      placeholder={'プロフィール'}
                      onClick={() => {
                        navigate(`/profile/${store.profileId}`)
                      }}
                    />

                    <div
                      className="block sm:hidden"
                    >
                      <PullDownMenuButton
                        placeholder={'投稿する'}
                        onClick={() => {
                          navigate(`/post`)
                        }}
                      />

                      <PullDownMenuButton
                        placeholder={'フォロー新着'}
                        onClick={() => {
                          navigate(`/following-new`)
                        }}
                      />
                    </div>

                    <PullDownMenuButton
                      placeholder={'アカウント設定'}
                      onClick={() => {
                        navigate('/setting/account')
                      }}
                    />

                    <PullDownMenuButton
                      placeholder={'ログアウト'}
                      onClick={async () => {
                        try {
                          const refreshToken = await authService.getRefreshToken() ?? ''
                          await AuthClient.logout(refreshToken)
                          authService.logout()
                          store.logout()
                          loginState.logout()
                        } catch (e) {
                          console.error(e)
                        }
                        alert('ログアウトしました')
                        navigate('/login')
                      }}
                    />
                  </div>

                </div>
              }
            </div>

          </div>
        </>
      }
      <Outlet />
    </div>
  )
}