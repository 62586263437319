import ReactDOM from 'react-dom/client';
import { lazy, Suspense } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Header } from './page/global/header/Header';
import { isMobile } from 'react-device-detect';
import SearchPage from './page/search/SearchPage';
import { Footter } from './page/global/footer/Footer';

const Loading = () => {
  return <div className='h-96'>
  </div>
}

const MainVideoPlayer = lazy(() => import('./page/video/MainVideoPlayer'));
const MobileProfilePage = lazy(() => import('./page/profile/mobile/MobileProfilePage'));
const CreatePostPage = lazy(() => import('./page/post/create_post/CreatePostPage'));
const FollowingNew = lazy(() => import('./page/following/FollowingNew'));
const MainPage = lazy(() => import('./page/main/MainPage'));
const ProfilePage = lazy(() => import('./page/profile/desktop/ProfilePage'));
const RegisterPage = lazy(() => import('./page/auth/RegisterPage'));
const LoginPage = lazy(() => import('./page/auth/LoginPage'));
const RegisterConfirmPage = lazy(() => import('./page/auth/RegisterConfirmPage'));
const UpdateProfilePage = lazy(() => import('./page/profile/UpdateProfilePage'));
const SettingAccount = lazy(() => import('./page/account/SettingAccount'));
const ChangePasswordPage = lazy(() => import('./page/auth/ChangePasswordPage'));
const ChangePasswordConfirmPage = lazy(() => import('./page/auth/ChangePasswordConfirmPage'));
const ChangeEmailPage = lazy(() => import('./page/auth/ChangeEmailPage'));
const DeleteAccountPage = lazy(() => import('./page/auth/DeleteAccountPage'));
const ChangePostPage = lazy(() => import('./page/post/change_post/ChangePostPage'));
const PostWarningPage = lazy(() => import('./page/post_wanring/PostWarningPage'));
const TermPage = lazy(() => import('./page/term/TermPage'));
const GuidelinePage = lazy(() => import('./page/guideline/GuidelinePage'));
const NotifyPage = lazy(() => import('./page/notify/NotifyPage'));
const CorporateSupporters = lazy(() => import('./page/corporate_supporters/CorporateSupporters'))
const LustyKiss = lazy(() => import('./page/corporate_supporters/LustyKiss'));
const ReportPage = lazy(() => import('./page/report/ReportPage'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <>
      <Header />
      <Footter />
    </>
    ,
    children: [
      {
        index: true,
        element:
          <Suspense fallback={<Loading />}>
            <MainPage />
          </Suspense>
      },
      {
        path: '/search',
        element:
          <Suspense fallback={<Loading />}>
            <SearchPage />
          </Suspense>,
      },
      {
        path: '/post/:postId', element:
          <Suspense fallback={<Loading />}>
            <MainVideoPlayer />
          </Suspense>,
      },
      {
        path: '/profile/:profileId',
        element:
          <Suspense fallback={<Loading />}>
            {
              isMobile ? < MobileProfilePage /> : <ProfilePage />
            }
          </Suspense >
      },
      {
        path: '/setting/profile', element:
          <Suspense fallback={<Loading />}>
            <UpdateProfilePage />
          </Suspense>
      },
      {
        path: '/setting/account', element:
          <Suspense fallback={<Loading />}>
            <SettingAccount />
          </Suspense>
      },
      {
        path: '/post', element:
          <Suspense fallback={<Loading />}>
            <CreatePostPage />
          </Suspense>
      },
      {
        path: '/post/:postId/change', element:
          <Suspense fallback={<Loading />}>
            <ChangePostPage />
          </Suspense>
      },
      {
        path: '/following-new', element:
          <Suspense fallback={<Loading />}>
            <FollowingNew />
          </Suspense>
      },
      {
        path: '/register', element:
          <Suspense fallback={<Loading />}>
            <RegisterPage />
          </Suspense>
      },
      {
        path: '/login', element:
          <Suspense fallback={<Loading />}>
            <LoginPage />
          </Suspense>
      },
      {
        path: '/register/confirm/:preAuthId', element:
          <Suspense fallback={<Loading />}>
            <RegisterConfirmPage />
          </Suspense>
      },
      {
        path: '/post/warning', element:
          <Suspense fallback={<Loading />}>
            <PostWarningPage />
          </Suspense>
      },
      {
        path: '/notify', element:
          <Suspense fallback={<Loading />}>
            <NotifyPage />
          </Suspense>
      },
      {
        path: '/change_password', element:
          <Suspense fallback={<Loading />}>
            <ChangePasswordPage />
          </Suspense>
      },
      {
        path: '/change_password/confirm/:token', element:
          <Suspense fallback={<Loading />}>
            <ChangePasswordConfirmPage />
          </Suspense>
      },
      {
        path: '/change_email', element:
          <Suspense fallback={<Loading />}>
            <ChangeEmailPage />
          </Suspense>
      },
      {
        path: '/delete_account', element:
          <Suspense fallback={<Loading />}>
            <DeleteAccountPage />
          </Suspense>
      },
      {
        path: '/terms', element:
          <Suspense fallback={<Loading />}>
            <TermPage />
          </Suspense>
      },
      {
        path: '/guideline', element:
          <Suspense fallback={<Loading />}>
            <GuidelinePage />
          </Suspense>
      },
      {
        path: '/corporate-supporters', element:
          <Suspense fallback={<Loading />}>
            <CorporateSupporters />
          </Suspense>
      },
      {
        path: '/corporate-supporters/lusty-kiss', element:
          <Suspense fallback={<Loading />}>
            <LustyKiss />
          </Suspense>
      },
      {
        path: '/report', element:
          <Suspense fallback={<Loading />}>
            <ReportPage />
          </Suspense>
      }
    ],
  },
]);



root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();