import { TextCheckButton } from "../shared_component/TextCheckButton";
import { Spacer } from "../../../component/Spacer";
import { IoIosOptions } from "react-icons/io";
import { useThumbnailListStore } from "../../search/SearchStore";

export const FilterOptionButton = () => {
  const store = useThumbnailListStore()
  return (
    <div className={'text-sm pr-3'}>
      <div className={'flex justify-end items-center'}>
        <button onClick={store.filterButtonTapped}>
          <div className={'flex items-center rounded border border-neutral-400 p-1'}>
            <IoIosOptions />
            <p className={' text-neutral-600'}>除外オプション</p>
          </div>
        </button>
      </div>
      <Spacer size={10} axis={'vertical'} />
      {store.isFilterOptionShowing &&
        <>
          <div className={'flex justify-end'}>
            <TextCheckButton
              value={'ダンス'}
              checked={store.filterDance}
              onChange={store.filterDanceButtonTapped}
            />
          </div>
          <div className={'mr-auto flex flex-wrap items-center justify-end'}>
            {
              store.filters.map((item) =>
                <TextCheckButton
                  value={item.title}
                  checked={item.checked}
                  onChange={() => store.filterButtonSelected(item)}
                />
              )
            }
          </div>
        </>
      }
      <div className={'flex justify-end items-center'}>
        {store.isFilterOptionShowing && (
          <div className={'flex'}>
            <button onClick={store.filterAllSelectedTapped}>
              <p className={'p-1 text-blue-600'}>すべて選択</p>
            </button>
            <button onClick={store.filterAllRemoveTapped}>
              <p className={'p-1 text-blue-600'}>除外</p>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}