export const createDisplayTime = (time: number) => {
  const date = new Date(time)
  // 数秒前

  const now = new Date()
  const diff = now.getTime() - date.getTime()
  if (diff < 1000 * 60) {
    return `${Math.floor(diff / 1000)}秒前`
  }

  if (diff < 1000 * 60 * 60) {
    return `${Math.floor(diff / 1000 / 60)}分前`
  }

  if (diff < 1000 * 60 * 60 * 24) {
    return `${Math.floor(diff / 1000 / 60 / 60)}時間前`
  }

  if (diff < 1000 * 60 * 60 * 24 * 7) {
    return `${Math.floor(diff / 1000 / 60 / 60 / 24)}日前`
  }

  if (diff < 1000 * 60 * 60 * 24 * 30 * 12) {
    return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}週間前`
  }

  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours()}時${date.getMinutes()}分`
}