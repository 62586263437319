export const LazyImage = (
  { src, className, alt, bgWhite }: { src: string, className: string, alt?: string, bgWhite?: boolean }
) => {
  const clazz = bgWhite ? "bg-white " + className : "bg-neutral-200 " + className
  return (
    <img
      loading="lazy"
      className={clazz}
      style={{ visibility: 'hidden' }}
      onLoad={(e) => e.currentTarget.style.visibility = 'visible'}
      src={src}
      onError={(e) => e.currentTarget.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
      alt={alt}
    />
  )
}