import { useHeaderStore } from "./HeaderStore"

export const PullDownMenuButton = (
  {
    placeholder,
    onClick
  }:
    {
      placeholder: string,
      onClick: () => void
    }
) => {

  const store = useHeaderStore()

  return (
    <div className={'flex items-center justify-center hover:bg-neutral-100'}>
      <button
        className={'w-full px-4 py-1'}
        onClick={() => {
          onClick()
          store.hiddenDropDownMenu()
        }}
      >
        <p>{placeholder}</p>
      </button>
    </div>
  )
}