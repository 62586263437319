import classNames from 'classnames'

export const TextCheckButton = (
  {
    checked,
    value,
    onChange
  }
    : {
      checked: boolean,
      value: string,
      onChange: (checked: boolean) => void
    }
) => {

  const clazz = classNames(
    'rounded',
    'p-1',
    {
      'hover:bg-neutral-100': !checked,
    },
    {
      'border-neutral-300': !checked,
      'text-neutral-500': !checked
    },
    {
      'border-neutral-500': checked,
      'bg-gray-100': checked,
    }
  )
  return (
    <button
      onClick={() => {
        onChange(checked)
      }}
    >
      <div className={'flex p-1'}>
        <p className={clazz}>{value}</p>
      </div>
    </button>
  )
}