import { useEffect, useRef } from "react"
import { IoIosNotificationsOutline } from "react-icons/io"
import { create } from "zustand"
import { NotifyClient, NotifyData } from "../../../test/network/notify/NotifyClient"
import { URLs } from "../../../url/Urls"
import { createDisplayTime } from "../../../helper/CreateDisplayTime"
import { Link } from "react-router-dom"
import { FaHeart, FaUser } from "react-icons/fa"
import { Spacer } from "../../../component/Spacer"
import { LoadingIcon } from "../../../component/LoadingIcon"

export const Notifiycation = () => {

  const store = useChangePostStore()
  const iconRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const notifyCardRef = useRef<HTMLDivElement>(null)

  const handler = (e: MouseEvent) => {
    if (!iconRef.current) return
    if (iconRef.current.contains(e.target as Node)) {
      store.onTappedNotifyIcon()
    }

    if (!ref.current || !notifyCardRef.current) return

    if (!ref.current.contains(e.target as Node)) {
      store.closeNotify()
    } else {
      if (notifyCardRef.current.contains(e.target as Node)) {
        store.closeNotify()
      }
    }
  }


  useEffect(() => {
    store.init()
  }, [])

  useEffect(() => {
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [ref, iconRef, store.isShowing])

  return (
    <div
      className="relative z-50"
    >
      <button>
        <div
          ref={iconRef}
          className={'relative px-1 py-2 flex items-center hover:bg-neutral-100 rounded-md'}>
          <IoIosNotificationsOutline size={22} />
          {
            store.isExistNotify && (
              <div className="w-2 h-2 absolute top-1 right-0 bg-red-600 rounded-full" />
            )
          }
        </div>
      </button>

      {store.isShowing && (
        <div
          ref={ref}
          className="absolute right-0 top-12 h-96 w-80 sm:w-[350px] bg-white border border-gray-200 rounded-md shadow-lg text-neutral-800">
          <div
            ref={notifyCardRef}
            className="w-full flex flex-col justify-between items-center border-gray-200">
            <p className="font-bold border-b w-full border-neutral-200 py-2 text-center text-neutral-800">通知</p>
            {
              store.loading === 'loading' && (
                <div className="w-full flex justify-center items-center">
                  <Spacer axis="vertical" size={100} />
                  <LoadingIcon />
                </div>
              )
            }
            {
              store.loading === 'loaded' && (
                <NotifyList notifyList={store.notifyList} />
              )
            }
          </div>
        </div>
      )
      }
    </div>
  )

}

type Notification = {
  isShowing: boolean,
  isExistNotify: boolean,
  init: () => void,
  onTappedNotifyIcon: () => void,
  closeNotify: () => void,
  notifyList: NotifyData[],
  loading: 'loading' | 'loaded'
}

export const useChangePostStore = create<Notification>((set, get) => ({
  notifyList: [],

  isShowing: false,
  isExistNotify: false,
  loading: 'loading',

  async init() {
    const response = await NotifyClient.getUserNotify()
    response.notifyCount != 0 && set({ isExistNotify: true })
  },

  async onTappedNotifyIcon() {
    set({ isShowing: true, isExistNotify: false, loading: 'loading' })
    const response = await NotifyClient.getNotifies()
    set({ notifyList: response.notifies, loading: 'loaded' })
  },

  closeNotify() {
    set({ isShowing: false, loading: 'loading' })
  }
}))

const NotifyList = (
  { notifyList }: { notifyList: NotifyData[] }
) => {
  return (
    <div className="w-full h-80 overflow-y-scroll overscroll-contain">
      {
        notifyList.map((notify) => {
          return (
            <NotifyCard key={notify.notifyId} notifyData={notify} />
          )
        })
      }
    </div >
  )
}

const NotifyCard = (
  { notifyData }: { notifyData: NotifyData }
) => {

  if (notifyData.type === 'COMMENT') {
    const profileData = notifyData.payload.profileData
    const postData = notifyData.payload.postData
    const commentData = notifyData.payload.commentData

    if (profileData === null || postData === null || commentData === null) {
      return <></>
    }

    const url = `${URLs.MEDIA_CACHE}/id/${profileData.iconId}.jpg`

    return (
      <div className="w-full border-b border-gray-200 hover:bg-gray-100">
        <Link
          to={'/post/' + postData.postId}
          className="w-full py-1"
        >
          <div className="text-sm flex items-center w-full py-2 px-2 gap-2">
            <Link
              className="shrink-0"
              to={'/profile/' + profileData.profileId}
            >
              <img className="h-10 w-10 rounded-full shrink-0" src={url} />
            </Link>
            <div className="w-full">
              <p>
                <span className="font-bold">{profileData.name}</span>さんが<span className="font-bold">{postData.title}</span>にコメントしました
              </p>
              <Spacer axis="vertical" size={3} />
              <p className="text-neutral-700">{commentData.text}</p>
              <p className="text-xs text-gray-400 text-end">
                {createDisplayTime(notifyData.createdAt * 1000)}
              </p>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  const data = createCardData({ notifyData })

  if (data?.to === undefined || data?.context === undefined) {
    return <></>
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-2b border-b border-gray-200 w-full hover:bg-gray-100">

        <Link
          className="w-full p-2"
          to={data.to}
        >
          <div className="flex w-full justify-left items-center gap-2">
            <div className="p-1">
              {data.icon}
            </div>
            <div className="w-full">
              {data.userIcon}
              <div className="text-sm">
                {data.context}
              </div>
              <p className="text-xs text-gray-400 text-end">
                {createDisplayTime(notifyData.createdAt * 1000)}
              </p>
            </div>
          </div>
        </Link>

      </div>
    </div>
  )
}

const createCardData = (
  {
    notifyData
  }: {
    notifyData: NotifyData
  }
) => {

  if (notifyData.type === 'LIKE') {
    const postData = notifyData.payload.postData
    const profileData = notifyData.payload.profileData
    if (postData === null || profileData === null) {
      return {}
    }

    const url = `${URLs.MEDIA_CACHE}/id/${profileData.iconId}.jpg`

    return {
      to: '/post/' + notifyData.payload.postData?.postId,
      context:
        <p>
          <span
            className="font-bold"
          >{profileData.name}</span>
          さんが
          <span
            className="font-bold"
          >{postData.title}</span>
          にいいねしました
        </p>
      ,
      icon: <FaHeart className={'text-red-600'} size={30} />,
      userIcon:
        <Link to={'/profile/' + profileData.profileId}>
          <img src={url} className="w-8 h-8 rounded-full" />
        </Link>
    }
  }

  if (notifyData.type === 'FOLLOW') {
    const profileData = notifyData.payload.profileData
    if (profileData === null) {
      return {}
    }
    const url = `${URLs.MEDIA_CACHE}/id/${profileData.iconId}.jpg`

    return {
      to: '/profile/' + profileData.profileId,
      context:
        <p>
          <span className="font-bold">
            {profileData.name}
          </span>
          さんがフォローしました
        </p>,
      icon: <FaUser className={'text-blue-600'} size={30} />,
      userIcon:
        <Link to={'/profile/' + profileData.profileId}>
          <img src={url} className="w-8 h-8 rounded-full" />
        </Link>
    }
  }
}