import React from 'react';

export const Spacer = (
  {
    size,
    axis,
    ...delegated
  }
    :
    {
      size: number,
      axis: 'vertical' | 'horizontal',
    }
) => {
  const width = axis === 'vertical' ? 1 : size;
  const height = axis === 'horizontal' ? 1 : size;
  return (
    <span
      style={{
        display: 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
        flexShrink: 0,
      }}
      {...delegated}
    />
  );
};