import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { useState } from "react";

export const PasswordInput = (
  {
    onPasswordChange,
  }: {
    onPasswordChange: (password: string) => void,
  }
) => {

  const [isShowingPassword, setIsShowingPassword] = useState<boolean>(false)

  const chaneShowPassword = () => {
    setIsShowingPassword(!isShowingPassword)
  }

  return (
    <div className={'relative'}>
      <button
        onClick={chaneShowPassword}>
        {isShowingPassword &&
          <IoEyeSharp
            className={'text-neutral-500 absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center'}
          />
        }
        {!isShowingPassword &&
          <IoEyeOffSharp
            className={'text-neutral-500 absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center'}
          />
        }
      </button>
      <input
        tabIndex={0}
        onChange={e => onPasswordChange(e.currentTarget.value)}
        type={isShowingPassword ? "text" : "password"}
        placeholder={'パスワード'}
        className={'text-neutral-700 placeholder:text-neutral-600 border-neutral-200 bg-gray-100 w-full rounded-md p-3  outline-blue-500'}
      />
    </div>
  )
}