import { BaseError, FetchClient } from "../../../network/AnishareFetch";
import { URLs } from "../../../url/Urls";

import {
  AccessTokenRequest,
  ChangeEmailCheckPasswordRequest, ChangeEmailConfirmRequest, ChangeEmailNewEmailRequest,
  ChangePasswordConfirmRequest,
  ChangePasswordSendEmailRequest,
  LoginError, LoginRequest,
  LoginSuccess, LogoutRequest, RefreshTokenUpdateRequest, RegisterConfirmRequest,
  RegisterError,
  RegisterRequest,
  RegisterSuccess
} from "./AuthRequest";

import {
  AccessTokenResponse, ChangePasswordCheckPasswordResponse,
  IsLoginResponse,
  LoginResponse,
  RefreshTokenUpdateResponse,
  RegisterConfirmResponse
} from "./AuthResponse";

import { CustomResponse } from "../../../data/CustomResponse";

export class AuthClient {

  static async register(email: string, password: string, token: string): Promise<RegisterError | RegisterSuccess> {

    const registerRequest: RegisterRequest = {
      email: email,
      password: password,
      response: token
    }

    try {
      await FetchClient.postB<RegisterRequest, CustomResponse>(URLs.AUTH_REGISTER, registerRequest, false);
      return { isError: false, message: "" }
    } catch (e: any) {
      return { isError: true, message: e.message }
    }
  }

  static async getRegisterConfirm(preAuthId: string) {
    try {
      await FetchClient.get(`/auth/register/confirm/${preAuthId}`, false);
      return { isError: false, message: "" }
    } catch (e: any) {
      return { isError: true, message: e.message }
    }
  }

  static async registerConfirm(
    preAuthId: string,
    name: string,
    profileId: string,
  ) {

    const registerConfirmRequest: RegisterConfirmRequest = {
      pre_auth_id: preAuthId,
      name: name,
      profile_id: profileId
    }

    try {
      const result = await FetchClient.postB<RegisterConfirmRequest, RegisterConfirmResponse>(URLs.AUTH_REGISTER_CONFIRM, registerConfirmRequest, false);
      return { isError: false, message: "", refreshToken: result.refresh_token }
    } catch (e: any) {
      if (e instanceof BaseError) {
        return { isError: true, message: e.message, error_code: e.errorCode }
      }
      return { isError: true, message: e.message, error_code: "" }
    }
  }

  static async login(email: string, password: string): Promise<LoginError | LoginSuccess> {

    const loginRequest: LoginRequest = {
      email: email,
      password: password
    }

    try {
      const result = await FetchClient.postB<LoginRequest, LoginResponse>(URLs.AUTH_LOGIN, loginRequest, false);
      return { isError: false, message: "", refreshToken: result.refresh_token }
    } catch (e: any) {
      if (e instanceof BaseError) {
        return { isError: true, message: e.message, errorCode: e.errorCode }
      }
      return { isError: true, message: "", errorCode: "" }
    }
  }

  static async accessToken(refreshToken: string) {

    const accessTokenRequest: AccessTokenRequest = {
      refresh_token: refreshToken
    }

    try {
      const result = await FetchClient.postB<AccessTokenRequest, AccessTokenResponse>(URLs.AUTH_ACCESS, accessTokenRequest, false);
      return { isError: false, message: "", accessToken: result.access_token }
    } catch (e: any) {
      return { isError: true, message: e.message }
    }
  }

  static async refreshTokenUpdate(refreshToken: string) {

    const refreshTokenUpdateRequest: RefreshTokenUpdateRequest = {
      refresh_token: refreshToken
    }

    try {
      const result = await FetchClient.postB<RefreshTokenUpdateRequest, RefreshTokenUpdateResponse>(URLs.AUTH_REFRESH, refreshTokenUpdateRequest, false);
      return { isError: false, message: "", refreshToken: result.refresh_token }
    } catch (e: any) {
      return { isError: true, message: e.message }
    }
  }

  static async isLogin() {
    const result = await FetchClient.get(`/auth/is_login`, true);
    return await result.json() as IsLoginResponse
  }

  static async logout(refreshToken: string) {

    const request: LogoutRequest = {
      refreshToken: refreshToken
    }

    return await FetchClient.postB<LogoutRequest, CustomResponse>(`/auth/logout`, request, true, "POST");
  }


  static async changePasswordSendEmail(email: string) {

    const request: ChangePasswordSendEmailRequest = {
      email: email
    }

    return await FetchClient.postB<ChangePasswordSendEmailRequest, CustomResponse>(`/auth/change_password/send_email`, request, false);
  }

  static async changePasswordConfirm(password: string, token: string) {

    const request: ChangePasswordConfirmRequest = {
      password: password,
      token: token
    }

    return await FetchClient.postB<ChangePasswordConfirmRequest, CustomResponse>(`/auth/change_password/confirm`, request, false);
  }

  static async changeEmailCheckPassword(password: string) {
    const request = {
      password: password
    }

    return await FetchClient.postB<ChangeEmailCheckPasswordRequest, ChangePasswordCheckPasswordResponse>(`/auth/change_email/check_password`, request, true);
  }

  static async changeEmailNewEmail(newEmail: string) {

    const request = {
      new_email: newEmail,
    }

    return await FetchClient.postB<ChangeEmailNewEmailRequest, CustomResponse>(`/auth/change_email/new_email`, request, true);
  }

  static async changeEmailConfirm(token: number) {
    const request = {
      token: token
    }
    return await FetchClient.postB<ChangeEmailConfirmRequest, CustomResponse>(`/auth/change_email/confirm`, request, true);
  }

  static async deleteAccount() {
    return await FetchClient.postB<{}, CustomResponse>(`/auth/delete_account`, {}, true, "DELETE");
  }

}