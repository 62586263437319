import { FetchClient } from "../../../network/AnishareFetch";
import { GetSettingResponse, UpdateAgeLimitButtonRequest } from "./UpdateAgeLimitButtonRequest";
import { CustomResponse } from "../../../data/CustomResponse";
import { AgeLimitButton } from "../../../page/main/MainStore";
import { AgeLimitLocalStorageService } from "../../../infra/AgeLimitLocalStorageService";

export class SettingClient {

  static async updateAgeLimitButton(ageLimitButton: AgeLimitButton) {

    const request: UpdateAgeLimitButtonRequest = {
      age_limit_button: ageLimitButton
    }

    return await FetchClient.postB<UpdateAgeLimitButtonRequest, CustomResponse>(`/setting/age_limit_button`, request, true, "POST")
  }

  static async getSetting(): Promise<GetSettingResponse> {
    try {
      const response = await FetchClient.get(`/settings`, true)
      return await response.json() as GetSettingResponse
    } catch {
      const isAdult = AgeLimitLocalStorageService.isAdult()

      return {
        is_adult: isAdult,
        age_limit_button: 'ALL_AGE',
      } as GetSettingResponse
    }
  }

  static async updateUserIsAdult() {
    return await FetchClient.postB<{}, CustomResponse>(`/setting/user_is_adult`, {}, true, "POST")
  }
}