import { useThumbnailListStore } from "../../search/SearchStore";
import {TextCheckButton} from "../shared_component/TextCheckButton";

export const OriginalSearchButton = () => {
  const store = useThumbnailListStore()
  return (
    <div className={'p-4  items-center text-sm whitespace-nowrap'}>
      <div className={'flex items-center'}>
        <p className={'text-neutral-800 pr-2 font-bold'}>オリジナル要素</p>
        {
          store.originals.map((original) => {
            return (
              <TextCheckButton
                key={original.title}
                checked={original.selected}
                value={original.title}
                onChange={() => {
                  store.originalButtonTapped(original)
                }}
              />
            )
          })
        }
      </div>
    </div>
  )
}