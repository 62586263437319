import { create } from "zustand"
import { SearchClient } from "../../test/network/search/SearchClient"
import { PostVideoData } from "../../data/CustomResponse"
import { AgeLimitButton } from "../main/MainStore"
import { SettingClient } from "../../test/network/setting/SettingClient"

export type ThumbnailList = {
  currentPage: number,
  totalSize: number,
  pageNationButtonTapped: (page: number) => void

  isFilterOptionShowing: boolean,
  style: '2D' | '3D' | 'REAL',
  categories: CategoryItem[],
  originals: OriginalItem[],
  filters: FilterItem[],
  sort: SortItem[],
  filterDance: boolean,

  searchTagValue: string,

  filterDanceButtonTapped: () => void,
  clearButtonTapped: () => void,

  searchPostVideoDataList: PostVideoData[],
  searchTagValueChange: (value: string) => void,
  searchTapped: () => void,
  setAgeLimitButton: (ageLimit: AgeLimitButton) => void,
  leave: () => void,
  init: (searchValue: string, isLogin: boolean) => void,
  refreshSearchPostVideoDataList: () => void,
  styleButtonTapped: (style: '2D' | '3D' | 'REAL') => void,
  categoryButtonTapped: (categoryItem: CategoryItem) => void,
  originalButtonTapped: (originalItem: OriginalItem) => void,
  filterButtonTapped: () => void,
  filterButtonSelected: (filterItem: FilterItem) => void,
  filterAllSelectedTapped: () => void,
  filterAllRemoveTapped: () => void,
  sortButtonTapped: (sort: string) => void,

  isLogin: boolean,
  ageLimitButton: AgeLimitButton | undefined,
  isAdult: boolean | undefined,

  state: 'loading' | 'loaded'
}

type OriginalItem = {
  title: string,
  selected: boolean,
  type: string,
}

type FilterItem = {
  title: string,
  checked: boolean,
  type: string | null,
}

type SortItem = {
  value: string,
  selected: boolean,
  type: 'NEW' | 'OLD'
}

export const useThumbnailListStore = create<ThumbnailList>((set, get) => ({
  isLogin: false,
  currentPage: 1,
  totalSize: 0,
  modalShowing: "none",
  state: 'loading',
  ageLimitButton: undefined,
  isAdult: undefined,

  leave() {
    set({ searchPostVideoDataList: [], state: 'loading' })
  },

  pageNationButtonTapped: async (page: number) => {
    set({ currentPage: page, searchPostVideoDataList: [] })

    get().refreshSearchPostVideoDataList()
  },

  setAgeLimitButton: (ageLimit: AgeLimitButton) => {
    set({ ageLimitButton: ageLimit })
    get().refreshSearchPostVideoDataList()
  },

  searchPostVideoDataList: [],
  isFilterOptionShowing: false,
  style: "3D",

  searchTagValue: '',

  clearButtonTapped: () => {
    set({ searchTagValue: '' })
  },

  filterDanceButtonTapped: () => {
    set({ filterDance: !get().filterDance })
    get().refreshSearchPostVideoDataList()
  },

  searchTagValueChange: (value) => {
    set({ searchTagValue: value })
  },

  searchTapped: () => {
    get().refreshSearchPostVideoDataList()
  },

  categories: [
    {
      title: '指定なし',
      checked: true,
      type: 'UnSpecified3D'
    },
    {
      title: 'blender Maya',
      checked: false,
      type: "BLENDER_MAYA_TYPE"
    },
    {
      title: 'MMD',
      checked: false,
      type: "MMD"
    },
    {
      title: 'VRChat',
      checked: false,
      type: "VRChat"
    },
    {
      title: 'CG,リアル',
      checked: false,
      type: 'CG_REAL'
    },
    {
      title: 'コイカツ',
      checked: false,
      type: "KOIKATSU"
    },
  ],

  originals: [
    {
      title: "キャラクター",
      selected: false,
      type: "CHARACTER"
    },
    {
      title: "モデル",
      selected: false,
      type: "MODEL"
    },
    {
      title: "モーション",
      selected: false,
      type: "MOTION"
    }
  ],

  filters: [
    {
      title: 'VRChat',
      checked: false,
      type: "VRChat"
    },
    {
      title: 'MMD',
      checked: false,
      type: "MMD"
    },
    {
      title: 'CG,リアル',
      checked: false,
      type: 'CG_REAL'
    },
    {
      title: 'コイカツ',
      checked: false,
      type: "KOIKATSU"
    },
  ],

  sort: [
    {
      value: "新しい順",
      selected: true,
      type: "NEW",
    },
    {
      value: "古い",
      selected: false,
      type: "OLD",
    }
  ],

  ageLimit: [
    {
      value: "すべて",
      selected: false,
      type: "ALL"
    },
    {
      value: "全年齢",
      selected: true,
      type: "ALL_AGE"
    },
    {
      value: "R18",
      selected: false,
      type: "R18"
    },
  ],

  filterDance: false,

  init: async (searchValue, isLogin) => {
    const response = await SettingClient.getSetting()
    set({
      isAdult: response.is_adult,
      ageLimitButton: response.age_limit_button,
      searchTagValue: searchValue,
      isLogin: isLogin
    })
    get().refreshSearchPostVideoDataList()
  },

  refreshSearchPostVideoDataList: async () => {
    const ageLimitButton = get().ageLimitButton

    if (ageLimitButton === undefined) {
      return
    }

    set({ state: 'loading' })

    const sort = get().sort.filter((value) => value.selected)[0].type

    const categories = get().categories
    const originalTypes = get().originals.filter((value) => value.selected).map((value) => value.type)

    const category = categories.filter((value) => value.checked)[0]

    const filterCategories = get().filters.filter((value) => value.checked).map((value) => value.type).filter((value): value is string => value !== null);

    const isFilterDance = get().filterDance

    const tag = get().searchTagValue
    const response = await SearchClient.search(sort, tag, get().currentPage, category.type, originalTypes, ageLimitButton, filterCategories, isFilterDance)

    set({ searchPostVideoDataList: response.postVideoData, totalSize: response.total_hits, state: 'loaded' })
  },

  styleButtonTapped: (style) => {

    const currenStyle = get().style

    if (currenStyle === style) {
      return
    }

    set({ currentPage: 1 })
    if (style === "2D") {

      const filters = [
        { title: '手書き', checked: false, type: 'HandWrite' },
        { title: 'カットアウト(うごくイラスト)', checked: false, type: 'MoveIllust' },
        { title: 'ピクセル', checked: false, type: 'Pixel' },
        { title: 'うごくメモ帳', checked: false, type: 'UGOMEMO' }
      ]

      const style2Dcategories = [
        { title: '指定なし', checked: true, type: 'UnSpecified2D' },
        ...filters,
      ]

      set({
        filters: filters,
        categories: style2Dcategories,
        style: style,
        originals: [
          { title: 'キャラクター', type: 'CHARACTER_2D', selected: false },
        ]
      })
      get().refreshSearchPostVideoDataList()
      return
    }

    if (style === "3D") {
      const filters = [
        { title: 'blender Maya', checked: false, type: 'BLENDER_MAYA_TYPE' },
        { title: 'MMD', checked: false, type: 'MMD' },
        { title: 'VRChat', checked: false, type: 'VRChat' },
        { title: 'CG,リアル', checked: false, type: 'CG_REAL' },
        { title: 'コイカツ', checked: false, type: 'KOIKATSU' }
      ]

      const style3Dcategories = [
        { title: '指定なし', checked: true, type: 'UnSpecified3D' },
        ...filters,
      ]

      set({
        style: style,
        filters: filters,
        categories: style3Dcategories,
        originals: [
          { title: 'キャラクター', type: 'CHARACTER_3D', selected: false },
          { title: 'モデル', type: 'MODEL', selected: false },
          { title: 'モーション', type: 'MOTION', selected: false },
        ]
      })
      get().refreshSearchPostVideoDataList()
      return
    }

    if (style === "REAL") {
      const filters = [
        { title: 'フェルト', checked: false, type: 'FELT' },
        { title: 'クレイ', checked: false, type: 'CLAY' },
        { title: 'フィギュア', checked: false, type: 'FIGURE' },
      ]

      const styleRealCategories = [
        { title: '指定なし', checked: true, type: 'UnSpecifiedReal' },
        ...filters,
      ]

      set({
        style: style,
        filters: filters,
        categories: styleRealCategories,
        originals: [
          { title: 'キャラクター', type: 'CHARACTER_REAL', selected: false },
        ]
      })
      get().refreshSearchPostVideoDataList()
    }

  },

  categoryButtonTapped: (category) => {
    const state = get()
    const categories = state.categories
    const currentItem = categories.filter((value) => value.checked)[0]
    if (currentItem === category) {
      return
    }
    const currentItemIndex = categories.indexOf(currentItem)
    state.categories[currentItemIndex] = { ...currentItem, checked: false }

    const tappedItemIndex = categories.indexOf(category)
    categories[tappedItemIndex] = { ...category, checked: true }
    set({ categories: categories, currentPage: 1 })

    get().refreshSearchPostVideoDataList()
  },

  originalButtonTapped: (value) => {
    const state = get()
    const originItems = state.originals
    const selectedItemIndex = originItems.indexOf(value)
    originItems[selectedItemIndex] = {
      ...value,
      selected: !value.selected
    }
    set({ originals: originItems, currentPage: 1 })
    get().refreshSearchPostVideoDataList()
  },

  filterButtonTapped: () => {
    set({ isFilterOptionShowing: !get().isFilterOptionShowing })
  },

  filterButtonSelected: (filterItem) => {
    const state = get()
    const filterItems = state.filters
    const currentItemIndex = filterItems.indexOf(filterItem)
    filterItems[currentItemIndex] = { ...filterItem, checked: !filterItem.checked }
    set({ filters: filterItems, currentPage: 1 })
    get().refreshSearchPostVideoDataList()
  },

  sortButtonTapped: (sort) => {

    const foo = get().sort.filter((value) => value.value === sort)[0]
    const index = get().sort.indexOf(foo)
    const sorts = get().sort.map((value) => ({ ...value, selected: false }))
    sorts[index] = { ...foo, selected: true }

    set({ sort: sorts, currentPage: 1 })
    get().refreshSearchPostVideoDataList()
  },

  filterAllSelectedTapped: () => {
    const state = get()
    const filterUpdated = state.filters.map((value) => ({ ...value, checked: true }))
    set({ filters: filterUpdated, currentPage: 1 })

    get().refreshSearchPostVideoDataList()
  },

  filterAllRemoveTapped: () => {
    const state = get()
    const filterUpdated = state.filters.map((value) => ({ ...value, checked: false }))
    set({ filters: filterUpdated, currentPage: 1 })

    get().refreshSearchPostVideoDataList()
  },
}))

export type CategoryItem = {
  title: string,
  checked: boolean,
  type: string,
}
