import { CustomResponse } from "../data/CustomResponse";
import { authService } from "../page/auth/AuthService";

export interface BaseError {
  new(message: string, error_code: string): Error
}

export class BaseError implements Error {
  constructor(message: string, errorCode: string) {
    this.message = message;
    this.errorCode = errorCode;
  }

  message: string;
  errorCode: string;
}

const getAnishareApiUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://api.anishare.net';
  }

  const hostname = document.location.hostname

  return `http://${hostname}:8080`;
}

export class FetchClient {
  static baseUrl = getAnishareApiUrl();

  static async get(url: string, needAuth: boolean, headers: Headers = new Headers()) {

    if (needAuth) {
      const token = await authService.getAccessToken()
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(this.baseUrl + url, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      const json = await response.json()
      throw new Error(`${json.message}`);
    }
    return response
  }

  static async postB<T, V extends CustomResponse>(
    url: string,
    data: FormData | T,
    needAuth: boolean,
    method: "POST" | "PUT" | "DELETE" = "POST"
  ): Promise<V> {

    const isFormData = data instanceof FormData;

    const headers = new Headers()

    if (needAuth) {
      const accessToken = await authService.getAccessToken()
      headers.append('Authorization', `Bearer ${accessToken}`);
    }

    if (!isFormData) {
      headers.append('Content-Type', 'application/json');
    }

    const response = await fetch(this.baseUrl + url, {
      method: method,
      headers,
      body: isFormData ? data : JSON.stringify(data)
    });

    if (!response.ok) {
      const a = await response.json() as V
      throw new BaseError(`${a.message}`, a.error_code);
    }
    return response.json() as Promise<V>
  }

  static async basicUpload(url: string, file: File) {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream', // ファイルのコンテンツタイプを指定
      },
    });

    return response
  }
}