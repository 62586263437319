import { FetchClient } from "../../../network/AnishareFetch";
import { MultiSearchTagRequest, SearchAuthIdRequest, SearchRequest } from "./SearchRequest";
import { MultiSearchTagResponse, SearchAuthIdResponse, SearchResponse } from "./SearchResponse";
import { CustomResponse, PostVideoData } from "../../../data/CustomResponse";
import { AgeLimit, AgeLimitButton } from "../../../page/main/MainStore";

type SearchVideoFromPOSTIdRequest = {
  postId: string,
  tags: string[],
  ageLimitButton: AgeLimitButton
}

interface SearchVideoFromPOSTIdResponse extends CustomResponse {
  result: PostVideoData[]
}

interface SearchNewPOstIdResonse extends CustomResponse {
  results: PostVideoData[]
}

export class SearchClient {

  static async search(
    sort: string,
    tag: string | null,
    page: number,
    category: string,
    originalTypes: string[],
    ageLimit: 'ALL' | 'ALL_AGE' | 'R18',
    filterCategory: string[],
    isFilterDance: boolean,
  ) {

    const request: SearchRequest = {
      sort: sort,
      tag: tag,
      categoryButton: category,
      filterButtonCategory: filterCategory,
      originalTypes: originalTypes,
      isFilterDance: isFilterDance,
      ageLimitButton: ageLimit,
      page: page
    }

    return await FetchClient.postB<SearchRequest, SearchResponse>(`/search`, request, false, "POST")
  }

  static async searchAuthId(authId: string, page: number, ageLimit: AgeLimitButton) {
    const request: SearchAuthIdRequest = {
      authId: authId,
      page: page,
      ageLimitButton: ageLimit
    }

    return await FetchClient.postB<SearchAuthIdRequest, SearchAuthIdResponse>(`/search/authId`, request, false, "POST")
  }

  static async searchMultiTags(tags: string[], ageLimit: AgeLimitButton) {
    const request: MultiSearchTagRequest = {
      tags: tags,
      ageLimitButton: ageLimit
    }

    return await FetchClient.postB<MultiSearchTagRequest, MultiSearchTagResponse>(`/multi-search/tags`, request, false, "POST")
  }

  static async searchVideoPostId(postId: string, tags: string[], ageLimit: AgeLimitButton) {
    const request: SearchVideoFromPOSTIdRequest = {
      postId: postId,
      tags: tags,
      ageLimitButton: ageLimit
    }

    return await FetchClient.postB<SearchVideoFromPOSTIdRequest, SearchVideoFromPOSTIdResponse>(`/search/video_from_post_id`, request, false, "POST")
  }

  static async searchNewestPostVideo(ageLimitButton: AgeLimitButton) {

    const request = {
      ageLimitButton: ageLimitButton
    }

    return await FetchClient.postB<{ ageLimitButton: AgeLimitButton }, SearchNewPOstIdResonse>(`/search/newest-posts`, request, false, "POST")
  }

}
