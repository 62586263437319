import React from "react";
import { Spacer } from "./Spacer";
import { LoadingIcon } from "./LoadingIcon";
import { PageNation } from "./PageNation";

export const ThumbnailContainer = (
  {
    children,
    state,
    currentPage,
    totalSize,
    onClick
  }:
    {
      children: React.ReactNode,
      state: 'loading' | 'loaded',
      currentPage?: number,
      totalSize?: number,
      onClick?: (page: number) => void,
    }
) => {
  return (
    <>
      {
        state === 'loaded' && currentPage !== undefined && totalSize !== undefined && onClick !== undefined &&
        <>
          <div className={'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 sm:gap-3'}>
            {children}
          </div>

          {
            totalSize !== 0 &&
            <div
              className={'w-8/12  mx-auto'}
            >
              <Spacer size={20} axis={'vertical'} />
              <PageNation
                onePageDataSize={30}
                currentPage={currentPage}
                totalSize={totalSize}
                onClick={(page) => {
                  onClick(page)
                }}
              />
            </div>
          }
        </>
      }

      {
        state === 'loaded' && currentPage === undefined && totalSize === undefined && onClick === undefined &&
        <div className={'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 sm:gap-3'}>
          {children}
        </div>
      }

      {
        state === 'loading' &&
        <div className="flex items-center justify-center">
          <Spacer size={150} axis={'vertical'} />
          <div className="flex justify-center">
            <LoadingIcon />
          </div>
          <Spacer size={200} axis={'vertical'} />
        </div>
      }

      <Spacer size={30} axis={'vertical'} />
    </>
  )
}