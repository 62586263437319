import classNames from "classnames";
import { AgeLimit, AgeLimitButton } from "../../main/MainStore";
import { SettingClient } from "../../../test/network/setting/SettingClient";
import { CheckUserIsAdultModal } from "../../main/CheckUserIsAdultModal";
import { AgeLimitLocalStorageService } from "../../../infra/AgeLimitLocalStorageService";
import { useEffect, useState } from "react";

export const AgeSelectButtonWrapper = (
  {
    onClick,
    is_adult,
    age_limit_button,
  }: {
    onClick: (ageLimitButton: AgeLimitButton) => void,
    is_adult: boolean | undefined,
    age_limit_button: 'ALL' | 'ALL_AGE' | 'R18' | undefined,
  }
) => {

  if (is_adult === undefined || age_limit_button === undefined) {
    return null
  }

  return (
    <AgeSelectButton
      onClick={onClick}
      is_adult={is_adult}
      age_limit_button={age_limit_button}
    />
  )
}

const AgeSelectButton = (
  {
    onClick,
    is_adult,
    age_limit_button,
  }: {
    onClick: (ageLimitButton: AgeLimitButton) => void,
    is_adult: boolean,
    age_limit_button: 'ALL' | 'ALL_AGE' | 'R18',
  }
) => {

  const calzz = classNames(
    'px-2',
    'py-1',
    'rounded-lg',
    'text-neutral-500',
    'hover:text-neutral-800',
  )

  const selectedClazz = classNames(
    'px-2',
    'py-1',
    'rounded-lg',
    'bg-white',
    'shadow',
  )

  useEffect(() => {
  }, [is_adult, age_limit_button]);

  const {
    state,
    values,
    selectedValue,
    selectedButton,
    onYesButtonTapped,
    onNoButtonTapped,
    onSelectedAgeLimit
  } = useAgeSelectButton(
    {
      is_adult: is_adult,
      age_limit_button: age_limit_button,
    }
  )

  return (
    <>
      {
        state.modalShowing !== "none" &&
        <div className={'fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'}
          onClick={onNoButtonTapped}
        >
          <CheckUserIsAdultModal
            onNoButtonTapped={onNoButtonTapped}
            onYesButtonTapped={onYesButtonTapped}
          />
        </div>
      }

      <div
        className="pr-2"
      >
        <div
          className={'text-sm flex gap-2 bg-gray-100 rounded-xl p-1'}>
          {
            values.map((value) => {
              return (
                <button key={value}>
                  <div
                    onClick={async () => {
                      if (value !== selectedValue) {
                        const result = await onSelectedAgeLimit(value)
                        if (result) {
                          onClick(result)
                        }
                      }
                    }}
                    className={
                      (value === selectedValue) ? selectedClazz : calzz
                    }
                  >
                    {value}
                  </div>
                </button>
              )
            })
          }
        </div>
      </div >
    </>
  )
}

const useAgeSelectButton = (
  {
    is_adult,
    age_limit_button,
  }: {
    is_adult: boolean,
    age_limit_button: 'ALL' | 'ALL_AGE' | 'R18',
  }
) => {

  const getSelectedAgeLimitsButton = (ageLimit: 'ALL' | 'ALL_AGE' | 'R18') => {
    const ageLimits: AgeLimit[] = [
      { value: '全て', selected: false, type: 'ALL' },
      { value: '全年齢', selected: true, type: 'ALL_AGE' },
      { value: 'R18', selected: false, type: 'R18' },
    ]
    return ageLimits.map((value) => {
      if (value.type === ageLimit) {
        return {
          ...value,
          selected: true
        }
      } else {
        return {
          ...value,
          selected: false
        }
      }
    })
  }

  const getSelectedAgeLimits = (ageLimit: string) => {
    const ageLimits: AgeLimit[] = [
      { value: '全て', selected: false, type: 'ALL' },
      { value: '全年齢', selected: true, type: 'ALL_AGE' },
      { value: 'R18', selected: false, type: 'R18' },
    ]
    return ageLimits.map((value) => {
      if (value.value === ageLimit) {
        return {
          ...value,
          selected: true
        }
      } else {
        return {
          ...value,
          selected: false
        }
      }
    })
  }

  const [state, setState] = useState<{
    modalShowing: "none" | "showing",
    isAdult: boolean,
    ageLimit: AgeLimit[],
  }>(
    {
      modalShowing: "none",
      isAdult: is_adult,
      ageLimit: getSelectedAgeLimitsButton(age_limit_button)
    }
  )
  const values = state.ageLimit.map((value) => value.value)
  const selectedValue = state.ageLimit.filter((value) => value.selected)[0].value
  const selectedButton = state.ageLimit.filter((value) => value.selected)[0].type

  const onYesButtonTapped = async () => {
    try {
      await SettingClient.updateUserIsAdult()
      setState({ ...state, modalShowing: "none", isAdult: true })
    } catch (e: any) {
      AgeLimitLocalStorageService.setIsAdult()
      setState({ ...state, modalShowing: "none", isAdult: true })
    }
  }

  const onNoButtonTapped = () => {
    setState({ ...state, modalShowing: "none", isAdult: false })
  }

  const onSelectedAgeLimit = async (ageLimit: string) => {
    if (!state.isAdult) {
      setState({ ...state, modalShowing: "showing" })
      return
    }
    const ageLimits = getSelectedAgeLimits(ageLimit)

    const ageLimitButton = ageLimits.filter((value) => value.selected)[0].type

    try {
      await SettingClient.updateAgeLimitButton(ageLimitButton)
    } catch (e) {
    }

    setState({
      ...state, ageLimit: ageLimits,
    })
    return ageLimitButton
  }

  return {
    state,
    values,
    selectedValue,
    selectedButton,
    onYesButtonTapped,
    onNoButtonTapped,
    onSelectedAgeLimit
  }
}