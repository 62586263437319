
export class AgeLimitLocalStorageService {


  static readonly key = 'is_adult'

  static setIsAdult = () => {

    localStorage.setItem(AgeLimitLocalStorageService.key, 'true')
  }

  static isAdult = () => {
    return localStorage.getItem(AgeLimitLocalStorageService.key) === 'true'
  }

}