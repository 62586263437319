import { Link } from "react-router-dom"

export const Footter = (
  {
    hiddenFotter
  }
    :
    {
      hiddenFotter?: boolean
    }
) => {

  return (
    <div>
      {
        !hiddenFotter &&
        <div className="border-r border-t border-neutral-200 w-full h-48 flex flex-col items-center justify-center">
          <div
            className="flex items-center justify-center gap-2"
          >
            <Link to="https://forms.gle/xD7FjU2PsUYwMHbC6">
              <p className="text-xs text-neutral-500 px-2 py-1 whitespace-nowrap cursor-pointer">お問い合わせ</p>
            </Link>
            <Link to="/terms">
              <p className="text-xs text-neutral-500 px-2 py-1 whitespace-nowrap cursor-pointer">利用規約</p>
            </Link>
            <Link to="/guideline">
              <p className="text-xs text-neutral-500 px-2 py-1 whitespace-nowrap cursor-pointer">ガイドライン</p>
            </Link>
            <div className="flex justify-center items-center">
              <Link to="/corporate-supporters">
                <p className="text-xs text-neutral-500 px-2 py-1 whitespace-nowrap cursor-pointer">サポーター</p>
              </Link>
              <Link to={'/corporate-supporters/lusty-kiss'}>
                <img loading={'lazy'} draggable="false" src="https://public.anishare.net/PSD%E7%94%A8%E3%83%AA%E3%83%AA%E3%83%B32%402x.png" alt="First Image" className="cursor-pointer w-7 hover:scale-x-[-1] " />
              </Link>
            </div>
          </div>

          <div
            className="flex items-center justify-start gap-2"
          >
            <Link to="/notify">
              <p className="text-xs text-neutral-500 px-2 py-1 whitespace-nowrap cursor-pointer">おしらせ</p>
            </Link>
          </div>
        </div>
      }
    </div>
  )
}