import classNames from "classnames";
import { Spacer } from "../../../component/Spacer";
import { useThumbnailListStore } from "../../search/SearchStore";

export const CategoryButton = () => {
  const store = useThumbnailListStore()

  return (
    <div className={'overflow-scroll overflow-x-visible overflow-y-hidden'}>
      <Spacer size={5} axis={'vertical'} />
      <div className={'text-sm flex whitespace-nowrap'}>
        {
          store.categories.map((item) => {
            return <TextButton key={item.title} value={item.title} selected={item.checked} onClick={() => { store.categoryButtonTapped(item) }} />
          })
        }
      </div>
      <div className={'border-b border-neutral-200 mx-auto'} />
    </div>
  )
}
const TextButton = (
  {
    value,
    selected,
    onClick
  }
    :
    {
      selected: boolean,
      value: string,
      onClick: () => void
    }
) => {
  const clazz = classNames(
    'p-1',
    'font-semibold',
    'hover:text-neutral-800',
    {
      "border-b-4": selected,
      "border-neutral-500": selected,
      "text-neutral-800": selected,
    },
    {
      "text-neutral-500": !selected,
    }
  )
  return (
    <div className={'px-1 '}>
      <button onClick={onClick}>
        <p className={clazz}>{value}</p>
      </button>
    </div>
  )
}