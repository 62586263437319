
const getAnishareCacheUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://cache.anishare.net";
  } else {
    const hostname = document.location.hostname;
    return `http://${hostname}:8084`
  }
}

export const URLs = {
  AUTH: "/auth",

  AUTH_REGISTER: "/auth/register",
  AUTH_REGISTER_CONFIRM: "/auth/register/confirm",
  AUTH_LOGIN: "/auth/login",

  AUTH_ACCESS: "/auth/access",
  AUTH_REFRESH: "/auth/refresh",

  PROFILE: "/profile",

  UPLOAD: "/upload",
  UPLOAD_VIDEO: "/upload/video",
  UPLOAD_THUMBNAIL: "/upload/thumbnail",

  MEDIA: "/media",
  MEDIA_AUTH: "/media/auth",

  MEDIA_CACHE: getAnishareCacheUrl(),
};