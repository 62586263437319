import { useEffect, useRef } from "react";
import { EmailAndPasswordSubmitForm } from "./EmailAndPasswordSubmitForm";
import { create } from "zustand";
import { AuthClient } from "../../test/network/auth/AuthClient";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "./AuthService";
import { Spacer } from "../../component/Spacer";
import { useHeaderStore } from "../global/header/HeaderStore";

const LoginPage = () => {
  const store = useLoginStore()
  const headerStore = useHeaderStore()

  const ref = useRef<HTMLVideoElement>(null)

  const nav = useNavigate()

  useEffect(() => {
    if (store.isSuccessfulLogin) {
      authService.login(store.refreshToken)
      headerStore.checkIsLogin()
      nav("/")
    }
  }, [store.isSuccessfulLogin]);

  return (
    <div className="bg-slate-100 min-h-screen border-t border-neutral-200 ">
      {
        store.isSuccessfulLogin && <div>
          <p>ログイン成功</p>
        </div>
      }

      <Spacer size={50} axis={"vertical"} />

      <EmailAndPasswordSubmitForm
        isShowingPassword={store.isShowingPassword}
        onEmailChange={store.onEmailChange}
        onPasswordChange={store.onPasswordChange}
        changeShowPassword={store.changeShowPassword}
        submitButtonPressed={store.submitButtonPressed}
        errorMessage={store.errorMessage}
        placeholdar={'ログイン'}
        isSubmitting={store.isSubmitting}
        isLogin={true}
      />

      <Spacer size={50} axis={"vertical"} />

      <div
        className="w-96 mx-auto rounded-2xl"
      >
        <video
          className="w-96 mx-auto rounded-2xl cursor-pointer"
          src="https://public.anishare.net/anishare_animation_logo_new.mp4"
          onClick={() => {
            if (ref?.current == null) {
              return
            }

            if (!ref.current.paused) {
              ref.current.pause()
            } else {
              ref.current.play()
            }

          }}

          ref={ref}

          autoPlay
          muted
          loop
          playsInline
        />

        <Link to='/profile/ghiacciomese'>
          <p className="text-neutral-700 text-xs text-right">@ghiacciomese</p>
        </Link>

      </div>

    </div>
  )
}

type Login = {
  id: string,
  password: string,
  isShowingPassword: boolean,
  isSubmitEmail: boolean
  email: string,
  isSuccessfulLogin: boolean,
  refreshToken: string,
  onEmailChange: (email: string) => void,

  isSubmitting: boolean,

  onPasswordChange: (email: string) => void,
  changeShowPassword: () => void,
  errorMessage: string,

  submitButtonPressed: () => void,
  logout: () => void
}


export const useLoginStore = create<Login>((set, get) => ({
  isSubmitEmail: false,
  email: "",
  isShowingPassword: false,
  refreshToken: "",

  logout() {
    set({
      isSuccessfulLogin: false,
      refreshToken: "",
      email: "",
      password: "",
      errorMessage: "",
      isShowingPassword: false,
      isSubmitEmail: false,
      isSubmitting: false
    })
  },

  id: "",
  password: "",
  errorMessage: "",
  isSuccessfulLogin: false,

  isSubmitting: false,

  onEmailChange: (value: string) => {
    set({ email: value })
  },

  onPasswordChange: (value: string) => {
    set({ password: value })
  },

  changeShowPassword: () => set((state) => {
    return { isShowingPassword: !state.isShowingPassword }
  }),

  submitButtonPressed: async () => {

    const state = get()

    set({ isSubmitting: true })
    const result = await AuthClient.login(
      state.email,
      state.password,
    )
    set({ isSubmitting: false })

    if (result.isError) {
      if (result.errorCode === "AUTH_EMAIL_OR_PASSWORD_FAILED") {
        set({ errorMessage: "メールアドレスまたはパスワードが間違っています" })
        return
      }
      alert(result.message)
    } else {
      set({ isSuccessfulLogin: true, refreshToken: result.refreshToken })
    }
  }
}))

export default LoginPage;