import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PostVideoData } from "../../data/CustomResponse";
import classNames from "classnames";
import { Spacer } from "../../component/Spacer";
import { URLs } from "../../url/Urls";
import { LazyImage } from "../../component/Image";

export const ThumbnailVideoPlayer = (
  {
    postVideoData,
    showProfile = false
  }
    :
    {
      postVideoData: PostVideoData,
      showProfile?: boolean
    }
) => {

  const {
    imageRef,
    videoRef,
    onMouseEnterVideoThumbnail,
    onMouseLeaveVideoThumbnail,
    state,
  } = useThumbnailStore(postVideoData.thumbnail_p360_id_jpeg, postVideoData.video_p360_no_sound_id_h264)

  const imgClazz = classNames(
    "object-cover",
    "aspect-square",
    "z-10 ",
    "sm:rounded-lg",
    {
      "hidden": state === 'video' || state === 'imageLoading',
      "animate-pulse": state === 'imageLoading'
    }
  )

  const videoClazz = classNames(
    "object-cover",
    "aspect-square",
    "z-0",
    "sm:rounded-lg",
    {
      "hidden": state === 'image' || state === 'imageLoading' || state === 'videoLoading'
    }
  )

  return (
    <div
      className={'cursor-pointer relative'}
      onMouseEnter={onMouseEnterVideoThumbnail}
      onMouseLeave={onMouseLeaveVideoThumbnail}
    >
      <Link to={`/post/${postVideoData.post_id}`}>
        <div className={'relative'}>
          {
            (state === 'imageLoading') && <div
              className={'object-cover aspect-square z-30 sm:rounded-lg bg-neutral-200 animate-pulse'}
            >
            </div>
          }
          <img className={imgClazz} alt={'video thumbnail image'} ref={imageRef} />

          {
            (state === 'video' || state === 'videoLoading') &&
            <video
              playsInline
              ref={videoRef}
              className={videoClazz}
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}
            />
          }

          <p className={'truncate font-bold  text-neutral-900'}>{postVideoData.title}</p>
        </div>
      </Link>
      {
        showProfile &&
        <div>
          <Spacer size={2} axis={'vertical'} />

          <Link to={`/profile/${postVideoData.profile_id}`}>
            <div className={'flex items-center'}>
              <LazyImage bgWhite src={`${URLs.MEDIA_CACHE}/id/${postVideoData.icon_100px}.jpg`} alt={'video thumbnail image'} className={"rounded-full h-7 w-7"} />
              <Spacer size={5} axis={'horizontal'} />
              <p className={'text-center truncate '}>{postVideoData.profile_name}</p>
            </div>
          </Link>
        </div>
      }
    </div>
  )
}

const useThumbnailStore = (thumbnailId: string, videoId: string) => {
  const imageRef = useRef<HTMLImageElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const videoUrl = `${URLs.MEDIA_CACHE}/id/${videoId}`
  const thumbnailUrl = `${URLs.MEDIA_CACHE}/id/${thumbnailId}.jpg`
  const [state, setState] = useState<'imageLoading' | 'image' | 'videoLoading' | 'video'>('imageLoading')
  const [isImageLoadedSuccessfully, setIsImageLoadedSuccessfully] = useState(false)

  useEffect(() => {
    const image = imageRef.current
    if (!image) {
      return
    }

    image.src = thumbnailUrl
    image.onload = () => {
      setState('image')
      setIsImageLoadedSuccessfully(true)
    }

    imageRef.current = image

    return () => {
      image.onload = null; // クリーンアップ
    };
  }, [imageRef]);

  useEffect(() => {
    const video = videoRef.current
    if (state !== 'videoLoading') {
      return
    }

    if (!video) {
      return
    }

    video.src = videoUrl

    video.onloadeddata = () => {
      setState('video')
    }

    return () => {
      video.onloadeddata = null
    };
  }, [state, videoRef]);

  const onMouseEnterVideoThumbnail = () => {
    setState('videoLoading')
  }

  const onMouseLeaveVideoThumbnail = () => {
    if (isImageLoadedSuccessfully) {
      setState('image')
    } else {
      setState('imageLoading')
    }
  }

  return {
    state,
    imageRef,
    videoRef,
    onMouseEnterVideoThumbnail,
    onMouseLeaveVideoThumbnail,
  }
}