
import { create } from "zustand";
import { URLs } from "../../../url/Urls";
import { AuthClient } from "../../../test/network/auth/AuthClient";

export type LoginState = 'not_login' | 'login' | 'unload'

export type HeaderStore = {
  loginState: LoginState
  iconSrc: string | undefined
  userName: string
  profileId: string
  isShowingDropDownMenu: boolean
  init: () => void,
  checkIsLogin: () => void
  showDropDownMenu: () => void
  hiddenDropDownMenu: () => void
  login: () => void,
  logout: () => void,
  setIconSrc: (iconSrc: string) => void
  tappedHumbugerMenu: () => void,
  isShowingLeftMenu: boolean,
  authId: string
}

export const useHeaderStore = create<HeaderStore>((set, get) => ({
  loginState: 'unload',
  iconSrc: undefined,
  userName: '',
  profileId: '',
  authId: '',
  isShowingLeftMenu: false,
  headerShowing: true,
  isShowingDropDownMenu: false,

  tappedHumbugerMenu: () => {
    set({ isShowingLeftMenu: !get().isShowingLeftMenu })
  },

  init: () => {
    get().checkIsLogin()
  },

  setIconSrc: (iconSrc: string) => {
    set({ iconSrc: iconSrc })
  },

  login: () => {
    set({ loginState: 'login' })
  },

  checkIsLogin: async () => {
    try {
      const response = await AuthClient.isLogin()
      const iconUrl = `${URLs.MEDIA_CACHE}/id/${response.icon_id_300px}.jpg`

      let loginState: LoginState = 'unload'
      if (response.is_login) {
        loginState = 'login'
      } else {
        loginState = 'not_login'
      }
      set({
        loginState: loginState,
        iconSrc: iconUrl,
        userName: response.name,
        profileId: response.profile_id,
        authId: response.auth_id,
      })
    } catch (e) {
      set({ loginState: 'not_login' })
    }
  },

  showDropDownMenu: () => {
    set({ isShowingDropDownMenu: true })
  },

  hiddenDropDownMenu: () => {
    set({ isShowingDropDownMenu: false })
  },

  logout: async () => {
    set({
      loginState: 'unload',
      iconSrc: '',
      userName: '',
      profileId: '',
      authId: ''
    })
  }

}))